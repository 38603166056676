@import "./theme";

*,
::after,
::before {
	box-sizing: border-box;
	margin: 0 auto;
	padding: 0;
	color: $color-black;
	font-family: $font-family;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

a {
	text-decoration: none;
}

p {
	color: $color-black;
	font-family: $font-family;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

input,
textarea {
	width: 100%;
	display: block;
	border: none;
	outline: none;
	background: none;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	padding: 1em 0.5em;
	border: none;
	border-radius: $border-radius;
	border: 1px solid rgba(0, 0, 0, 0);
	background-color: $color-light-grey;
	font-family: $font-family;
	transition: all 0.2s ease-in-out;

	&.focus,
	&:focus-within {
		border-color: $color-blue;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
		background-color: $color-white;
	}

	&::placeholder {
		font-family: $font-family;
		font-weight: 400;
	}

	& + .invalid {
		display: none;
		font-family: $font-family;
		color: $color-red;
		font-size: 12px;
		padding: 0.5em 0.75em;
	}

	&:focus-visible {
		outline: none;
	}
}

input {
	padding: 1.5em 0.5em 0.5em 0.5em;
}

textarea {
	min-height: 140px;
}

button {
	border: unset;
	outline: unset;
	background: unset;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:focus-visible {
		outline: none;
	}

	&.red,
	&.white,
	&.black {
		a,
		p {
			padding: 1em;
			transition: all 0.2s ease-in-out;
			border: 1px solid rgba(0, 0, 0, 0);
			border-radius: 60px;
			text-align: center;
			overflow: hidden;
			font-size: 17px;
			font-weight: 600;
			width: 100%;
			display: inline-block;
		}
	}

	&.red {
		a,
		p {
			background-color: $color-red-light;
			border-color: $color-red-light;
			color: $color-white;
		}

		&:hover {
			a,
			p {
				background-color: $color-red;
				border-color: $color-red;
			}
		}
	}

	&.black {
		a,
		p {
			background-color: $color-black;
			border-color: $color-black;
			color: $color-white;
		}

		&:hover {
			a,
			p {
				background-color: $color-black-light;
				border-color: $color-black-light;
			}
		}
	}

	&.white {
		a,
		p {
			color: $color-black;
			background-color: $color-white;
			border-color: $color-black;
		}

		&:hover {
			a,
			p {
				background-color: #f7f7f7;
			}
		}
	}
}

ul,
ol {
	list-style: none;
	padding-left: 0;
}

address,
dl,
fieldset,
figure,
ol,
p,
pre,
ul {
	margin: 0 auto;
}

.progressBar {
	> .inner {
		max-width: 1350px;

		ul {
			transition: translate 300ms ease-in-out;

			li {
				position: relative;
				padding: 0 0 25px 0;
				text-align: center;
				height: 100%;
				line-height: 1;

				&.active {
					P {
						color: $color-black;

						.step {
							display: block;
						}
					}

					&::before {
						width: 50%;
					}

					&::after {
						opacity: 1;
					}
				}

				&.passed {
					p {
						.back,
						.edit {
							display: block;
						}

						.step {
							display: none;
						}
					}

					&::before {
						width: 100%;
					}
				}

				&.hidden {
					p {
						.back {
							display: none;
						}
					}
				}

				p {
					font-size: clamp(12px, 2vw, 16px);
					font-weight: 600;
					line-height: 1;
					color: #bdbdbd;
					display: inline-block;
					width: auto;
					position: relative;
					text-align: center;

					.back,
					.step,
					.edit {
						position: absolute;
						width: 100%;
						display: block;
						text-align: center;
						font-size: 12px;
						display: none;
						padding-top: 5px;
						line-height: 1;

						a {
							color: $color-blue;
							line-height: 1;
						}
					}

					.step {
						bottom: 0;
						translate: 0 60px;
						color: #bdbdbd;
						padding: 0;
					}

					.edit,
					.back {
						color: $color-blue;
						cursor: pointer;
					}
				}

				&::before {
					content: "";
					height: 1px;
					background-color: $color-blue;
					width: 0;
					position: absolute;
					bottom: 0;
					left: 0;
				}

				&::after {
					content: "";
					width: 20px;
					height: 20px;
					border-radius: 50%;
					background-color: #01338e;
					position: absolute;
					bottom: 0;
					left: 50%;
					translate: -50% 50%;
					opacity: 0;
				}
			}
		}
	}

	&.landingPage {
		> .inner {
			ul {
				grid-template-columns: auto;
				justify-content: start;

				li {
					padding: 20px $standard-padding-x;
				}
			}
		}
	}

	&.callback {
		> .inner {
			ul {
				grid-template-columns: repeat(3, 1fr);
				justify-content: space-between;

				li {
					width: 100%;
				}
			}
		}
	}

	&.corporate {
		> .inner {
			ul {
				grid-template-columns: repeat(4, 1fr);
				justify-content: space-between;

				li {
					width: 100%;
				}
			}
		}
	}

	&.broker {
		> .inner {
			ul {
				grid-template-columns: repeat(5, 1fr);
				justify-content: space-between;

				li {
					width: 100%;
				}
			}
		}
	}
}

.closingBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
}

.formInput {
	width: 100%;
	text-align: left;
	position: relative;

	&.initial {
		input {
			&:focus-visible {
				& + .label {
					top: 5px;
					font-size: 10px;
					left: 0.8em;
					z-index: 3;
				}
			}
		}

		.label {
			top: 1em;
			left: 0.5em;
			font-size: 14px;
			background-color: unset;
			z-index: 1;
		}
	}

	input {
		outline: none;

		&:focus-visible {
			outline: none;

			& + .label {
				top: 5px;
				font-size: 10px;
				left: 0.8em;
			}
		}
	}

	.label {
		position: absolute;
		top: 5px;
		left: 0.8em;
		font-size: 10px;
		color: #7a7a7a;
		transition: all 300ms ease;
		left: 0.8em;
		z-index: 3;
	}

	&.active {
		.closingBackground {
			display: block;
		}
	}

	&.invalid {
		.invalid {
			display: block;
		}
	}

	.invalid {
		display: none;
		font-family: $font-family;
		color: $color-red;
		font-size: 12px;
		padding: 0.5em 0.75em;
	}
}

.grid {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 0;
	align-items: center;
	align-content: center;
	justify-content: center;
}

.anchorText {
	font-size: 0px;
	line-height: 0px;
	color: $color-transparent;
}

#loading-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 $standard-padding-x;
	display: none;
	z-index: 9;

	&.active {
		display: grid;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $color-white;
		opacity: 0.6;
	}

	.spinner {
		animation: spinner 2s linear 0s infinite forwards;
		border: 3px solid $color-white;
		border-top-color: $color-blue;
		border-radius: 50%;
		height: 50px;
		width: 50px;
	}
}

#error-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 $standard-padding-x;
	display: none;
	z-index: 9;

	&.active {
		display: grid;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $color-white;
		opacity: 0.6;
	}

	> .inner {
		width: 100%;
		max-width: 880px;
		text-align: center;
		background-color: $color-white;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		padding: 50px $standard-padding-x;

		> p {
			margin-top: 20px;
			font-size: 14px;
			font-weight: 500;
		}

		.title {
			color: $color-blue;
			font-size: clamp(22px, 3vw, 24px);
			font-weight: 600;
			width: auto;
			display: inline-block;

			&::after {
				width: 20%;
				display: block;
				content: "";
				background-color: $color-red-light;
				height: 2px;
				margin-top: 10px;
			}
		}

		button {
			width: 100%;
			max-width: 250px;
			margin-top: 50px;
		}
	}
}

@keyframes spinner {
	from {
		rotate: 0deg;
	}

	to {
		rotate: 360deg;
	}
}
